
<mat-form-field>
  <input 
    matInput 
    (keyup)="gnommeFilter($event.target.value)" 
    placeholder="Find citizen or friends of">
</mat-form-field>

<div 
  
  class="gnomme-table-container mat-elevation-z8" >
  <table
    mat-table 
    matSort
    
    [dataSource]="gnommeSource"
    class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id. </th>
      <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

     <!-- Age Column -->
     <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef> Age </th>
      <td mat-cell *matCellDef="let element"> {{ element.age }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="gnommeColumns; sticky: true"></tr>
    <tr 
      mat-row 
      *matRowDef="let row; columns: gnommeColumns;"
      [ngClass]="{hovered: row.hovered, highlighted: selected ? selection.isSelected(row) : ''}"
      (click)="handleClick($event, row)"
    ></tr>
</table> 
<mat-paginator 
  [pageSizeOptions]="[10, 20, 50]" 
  showFirstLastButtons></mat-paginator>
</div>
<mat-card 
    *ngIf="!isReady"
    style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner 
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
<app-gnomme
  [selected]="selected" 
  [avgAge]="avgAge"
  [maxAge]="maxAge"
  [avgHeight]="avgHeight"
  (isClosed)="handleClosed()"
></app-gnomme>

<div *ngIf="errorData">
  <p style="color: red; margin-top: 20px; text-align: center;">
    Error getting the town citizens data.
  </p>
</div>
