<mat-toolbar color="primary">
  <button mat-icon-button class="menu-icon" [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>home</mat-icon>
      <a href="home">
        <span>Home</span>
      </a>
    </button>
    <button mat-menu-item>
      <mat-icon>location_city</mat-icon>
      <a href="list">
        <span>Citizens</span>
      </a>
    </button>
  </mat-menu>
  <span>Gnomme Town</span>
</mat-toolbar>