<div 
    class="wrapper" 
    *ngIf="selected" 
    (click)="handleClose()"
    ></div>
<div 
  class="gnomme-info" 
  *ngIf="selected">
  <div class="info-content">
    <figure>
      <div class="crop-height">
        <a 
          target="_blank"
          [href]="selected.thumbnail">
          <img class="scale" [src]="selected.thumbnail" />
        </a>
      </div>
      <figcaption>
        <button (click)="handleClose()">Close</button>
      </figcaption>
    </figure>
    <ul>
      <li> <b>Name: </b>{{ selected?.name }} </li>
      <li> 
        <b>Age: </b> {{ selected?.age }} years</li>
      <li>
        <div class="progress-bar-wrapper">
          <mat-progress-bar mode="determinate" [value]="percentGnommeAge"></mat-progress-bar>
          <span class="progress-bar-value"> {{ percentGnommeAge | number: '1.0-0' }}% old</span>
        </div>
      </li>
      <li> <b>Weight: </b>{{ selected?.weight | number: '1.0-0' }} kgs </li>
      <li> <b>Height: </b>{{ selected?.height | number: '1.0-0' }} cms </li>
      <li> <b>Hair color: </b><i class="hair" [style]="{ background: selected?.hair_color }"></i> </li>
      <li> <b>Gender: </b> {{ getGender() }} </li>
      <li> <b>Professions: </b> {{ selected?.professions.length > 0 ? selected?.professions : 'This citizen has no work yet'}} </li>
      <li> <b>Friends: </b> {{ selected?.friends.length > 0 ? selected.friends : 'This citizen has no friends yet' }} </li>
    </ul>
  </div>
</div>