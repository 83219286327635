<mat-card>
  <mat-card-header>
    <mat-icon>city</mat-icon>
    <mat-card-title>Gnomme City App</mat-card-title>
    <mat-card-subtitle>Welcome</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Welcome to Gnomme City App. Where you are able to find every citizen.
      Please feel free to click on each citizen to get the available information.
      You can use our filter to find quickly.
      
      Enjoy!
    </p>
  </mat-card-content>
  <mat-card-actions>
    <a href="list" class="card-link">Citizens List</a>
  </mat-card-actions>
</mat-card>
